import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import '../assets/styles/base/standard-page.scss';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AnouncementBar from '../components/home/announcement-bar';

const Terms = () => {
  const data = useStaticQuery(
    graphql`
      query {
        termsandconditions {
          seo {
            title
            canonical
            description
            og {
              description
              image
              title
              type
              url
            }
          }
          body
        }
      }
    `
  );

  const { seo, body } = data.termsandconditions;

  return (
    <Layout wrapperClass="standard-page" hideHeaderFooter={true} showSiteIndex={true}>
      <SEO
        title={seo.title}
        description={seo.title}
        canonical={seo.canonical}
        stucturedData=""
        ogTitle={seo.og.title}
        ogDescription={seo.og.description}
        ogImage={seo.og.image}
        ogType={seo.og.type}
        ogUrl={seo.og.url}
      />
      <AnouncementBar />
      <div
        className="py-7 container main-wrapper sm"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: body,
        }}
      />
    </Layout >
  );
};

export default Terms;
